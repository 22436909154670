.video-js {
  position: static;
  height: 100%; }

.bc-iframe, .bc-iframe body, .bc-player-0FpoJkrF3R_default, .bc-player-0FpoJkrF3R_default .vjs-poster {
  background-color: rgba(0, 0, 0, 0.5) !important;
  width: 100%;
  height: 100%; }

/* override default styles for overlay */
.videojs .vjs-overlay,
.vjs-overlay.vjs-overlay-top {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  min-height: 100%;
  margin: auto;
  left: 0;
  top: 0;
  background: none; }

/* hide the overlay */
.hide-overlay .vjs-overlay {
  display: none; }

/* hide controls */
.hide-controls.video-js .vjs-control-bar,
.hide-controls .vjs-big-play-button {
  display: none; }

/* for the registration form */
/* form layout */
.registration-form {
  font-family: sans-serif;
  font-size: 0.9em;
  color: #333; }

/* form header */
h1.registration-form {
  font-size: 1.1em; }

/* buttons */
button.registration-form {
  font-size: 18px;
  font-weight: bold;
  line-height: 14px;
  color: #43413f;
  background-color: transparent;
  border-width: 2px;
  border-style: solid;
  border-color: #43413f;
  border-radius: 42px;
  text-align: center;
  padding: 8px 14px 6px; }

button.registration-form:hover {
  background-color: #43413f;
  color: white;
  cursor: pointer; }

/* divs */
div.registration-form {
  background-color: #f5f5f5;
  opacity: 0.8;
  text-align: center;
  height: 100vh;
  padding-top: 15%; }

/* inputs */
div.registration-form input {
  width: 66%;
  display: inline; }

/* paragraphs */
p.registration-form {
  font-weight: bold;
  margin: 0; }

.background .vjs-tech {
  object-fit: cover; }

.background .vjs-control-bar {
  display: none; }

.background .video-js {
  pointer-events: none; }

.background .vjs-poster {
  background-size: cover; }
